<template>
  <huboo-page>
    <huboo-page-heading
      :heading="$t('subscriptionPricing.tablePageTitle') | title"
    ></huboo-page-heading>
    <huboo-table
      id="subscription-pricing"
      ref="subscription-pricing-table"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading"
      @row-clicked="onRowSelected"
      @update:options="handleUpdateOptions"
      @confirm-delete="onRemove"
      @remove="openConfirmationModal"
      :remove="isAdmin"
    >
      <template #actions>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="additionalPricingCreateButton"
        >
          {{ $t('subscriptionPricing.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>
    <subscription-pricing-edit-modal
      ref="edit-modal"
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <subscription-pricing-create-modal
      v-model="createModal"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />
  </huboo-page>
</template>

<script>
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import editModal from './CustomSubscriptionPricingEditModal.vue'
import createModal from './CustomSubscriptionPricingCreateModal.vue'

export default {
  name: 'SubscriptionPricing',
  mixins: [ClientMixin, ModalActionsMixin],
  components: {
    'subscription-pricing-edit-modal': editModal,
    'subscription-pricing-create-modal': createModal,
  },
  data() {
    return {
      editModal: false,
      editRequiresAdmin: false,
      createModal: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      selected: null,
    }
  },

  computed: {
    headers() {
      return [
        {
          text: title(this.$t('subscriptionPricing.tableHeaders.priceListCode')),
          sortable: false,
          value: 'priceListCode',
        },
        {
          text: title(this.$t('subscriptionPricing.tableHeaders.description')),
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('subscriptionPricing.tableHeaders.numberOfShipments')),
          sortable: false,
          value: 'numberOfShipments',
        },
        {
          text: title(this.$t('subscriptionPricing.tableHeaders.price')),
          sortable: false,
          value: 'price',
        },
        {
          text: title(this.$t('subscriptionPricing.tableHeaders.updatedAt')),
          sortable: false,
          value: 'updatedAt',
        },
      ]
    },

    subscriptionPriceLists() {
      const list = this.$store.getters['customSubscriptionPricing/getAll']
      if (Array.isArray(list))
        return list.map(l => ({
          ...l,
          loading: this.isDeleteLoading(l.id),
        }))
      else return []
    },

    items() {
      return this.subscriptionPriceLists.map(item => {
        return {
          ...item,
          createdAt: this.formatDate(item.createdAt),
          updatedAt: this.formatDate(item.updatedAt),
        }
      })
    },

    meta() {
      return this.subscriptionPriceLists.meta || { total: this.items.length }
    },

    url() {
      return '/subscription-price-list'
    },
  },

  methods: {
    fetch() {
      if (!this.loading) {
        const { options } = this
        const { page } = options
        const params = {}

        if (page) params.page = page
        this.$store.dispatch('customSubscriptionPricing/fetchCollection', {
          params,
        })
      }
    },

    onRemove() {
      this.$store.dispatch('customSubscriptionPricing/delete', this.selected.id).finally(() => {
        this.fetch()
        this.selected = ''
      })
    },

    isDeleteLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.url + id,
        method: 'DELETE',
      })
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
