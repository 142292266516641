<template>
  <modal-form
    :value="value"
    id="edit-subscription-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    @reset="onReset"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('subscriptionPricing.editModal.title') }}
    </template>

    <template #fields="{ errorMessages }">
      <div class="fields-container">
        <hub-text-field
          @keyup="onInputFocus('priceListCode')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.priceListCode) || []"
          class="modal-form__field"
          name="price-list-code"
          v-model="formData.priceListCode"
          :label="$t('subscriptionPricing.editModal.fields.priceListCode')"
        />
        <hub-text-field
          @keyup="onInputFocus('description')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.description) || []"
          class="modal-form__field"
          name="description"
          v-model="formData.description"
          :label="$t('subscriptionPricing.editModal.fields.description')"
        />
        <div class="break"></div>
        <hub-text-field
          @keyup="onInputFocus('numberOfShipments')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.numberOfShipments) || []"
          class="modal-form__field"
          name="shipments"
          v-model="formData.numberOfShipments"
          :label="$t('subscriptionPricing.editModal.fields.numberOfShipments')"
        />
        <hub-text-field
          @keyup="onInputFocus('price')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.price) || []"
          name="price"
          v-model="formData.price"
          :label="$t('subscriptionPricing.editModal.fields.price')"
          persistent-hint
        >
        </hub-text-field>
      </div>
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="$emit('close')" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
export default {
  name: 'SubscriptionPricingEditModal',
  props: {
    value: Boolean,
    selected: { type: Object, required: true },
  },

  data() {
    return {
      formData: {
        priceListCode: this.selected.priceListCode ?? '',
        description: this.selected.description ?? '',
        numberOfShipments: this.selected.numberOfShipments.toString() ?? '',
        price: this.selected.price.toString() ?? '',
      },
      activeField: '',
    }
  },

  computed: {
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/subscription-price-list/' + this.selected?.id,
      })
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    fields() {
      return Object.keys(this.formData)
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('customSubscriptionPricing/update', {
        id: this.selected?.id,
        data: { ...this.formData },
      })

      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },

    convertValues(object) {
      const convertedObject = {}
      // We need to convert the values to string.
      Object.keys(object).forEach(key => {
        convertedObject[key] = object[key].toString()
      })
      return convertedObject
    },

    onInputFocus(inputName) {
      this.activeField = inputName
    },

    onInputBlur() {
      this.activeField = ''
    },

    onReset() {
      this.formData = this.convertValues(this.selected)
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
